<template lang="pug">
div.view_container
  Header(v-on:is-small-screen="setIsSmallScreen")
  div.view_title 👍 Thanks, you're done!
  div.description
    span You can also share the link below on social media to invite more people.
    br
    span Just click the button below to copy the link.
    br
    br
  button.cta_secondary(v-if="currentProject.shareUrl" v-on:click="copyShareUrl(currentProject.shareUrl)")
    .wrapper {{currentProject.shareUrl}}
  button.cta_secondary(v-on:click="next('/')" ) Go home
  input#input_field_to_copy(type="text")
</template>

<script>
import Header from '../components/Header'
import { clearSelection } from '../lib/utils'

export default {
  name: 'Thanks',
  props: {

  },
  components: {
    Header,
  },
  computed: {
    currentProject () {
      return this.$store.state.currentProject
    },
    currentUser () {
      return this.$store.state.user.user
    },
  },
  data () {
    return {
      isSmallScreen: false,
      loading: false,
    }
  },
  apollo: {

  },
  methods: {
     setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    },
    copyShareUrl: function(url) {
      var copyText = document.getElementById("input_field_to_copy");

      copyText.value = url

      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy")
      clearSelection()
      this.$toast.success("Your share link is copied to the clipboard. Just go to your social media app and paste it in a post.", {timeout: 7000})
    },
    next: function (name) {
      if (name.match(/create/)) { this.$store.commit('clearProject') }
      this.$router.push(name)
    },
  },
  mounted: function() {
    // window.vue = this
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.title {
  font-family: gibsonsemibold;
  font-size: 26px;
  color: #151516;
  margin-bottom: 10px;
}
.description {
  font-family: gibsonregular;
  font-size: 20px;
  color: #79787D;
}

#input_field_to_copy {
  position:absolute;
  z-index: 1;
  top: -100px;
  left: 0px;
  opacity: 0;
}

button.cta {
  display: block;
  font-size: 20px;
}

button {
  display: block;
  font-size: 20px;
  margin: 0px 0px 20px 0px;
  padding: 20px 44px;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  max-width: 100%;;
}

.wrapper {
  overflow: hidden;
}

</style>
