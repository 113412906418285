<template lang="pug">
div#header
  a#logo_container.noselect(href="/")
    img.logo(:src="logo")
    img.logotype(v-show="false" :src="logotype")

  div.hover_menu(v-if="hoverMenu"  @mouseleave="hoverMenu=false")
    a.noselect(v-on:click="create") Create new video
    a.noselect(href="/") Your video projects
    a.noselect(href="/reminders/setup") Your birthday reminders
  span.noselect(v-show="!isSignedIn && showSignIn" v-bind:class="{ dark: useDark, smallScreen:isSmallScreen}")
    button.noselect(v-on:click="next('Login')") Sign In
  span.noselect(v-show="isSignedIn && showSignIn" v-bind:class="{ dark: useDark, smallScreen:isSmallScreen}")
    button(v-on:click="signOut")
      span.signed_in_email(v-show="!isSmallScreen" @mouseenter="hoverMenu=true") {{ currentUser.email }}&nbsp;
      span &nbsp;sign out
  span(v-show="showAbout" v-bind:class="{ dark: useDark, smallScreen:isSmallScreen}")
    button(v-on:click="next('About')") About
  span.noselect(v-show="showPrivacy" v-bind:class="{ dark: useDark, smallScreen:isSmallScreen}")
    button(v-on:click="next('Privacy')") Privacy
  span.noselect(v-show="showWork" v-bind:class="{ dark: useDark, smallScreen:isSmallScreen}")
    button.noselect(v-on:click="next('Slack')") For Teams
  div.clear
</template>

<script>
import config from '../appConfig'

export default {
  name: 'Header',
  props: {
  },
  components: {
  },
  computed: {
    logo () {
      return process.env.BASE_URL + "logo.svg"
    },
    logotype () {
      return this.useDark ? process.env.BASE_URL + "logotype.svg" : process.env.BASE_URL + "logotype white.svg"
    },
    showPrivacy () {
      return !this.isSignedIn && (
              this.$router.currentRoute.name == "Home" ||
              this.$router.currentRoute.name == "ShareHome" ||
              this.$router.currentRoute.name == "Terms" ||
              this.$router.currentRoute.name == "About" ||
              this.$router.currentRoute.name == "Login" ||
              this.$router.currentRoute.name == "SignUp")
    },
    showAbout () {
      return !this.isSignedIn && (
              this.$router.currentRoute.name == "Home" ||
              this.$router.currentRoute.name == "ShareHome" ||
              this.$router.currentRoute.name == "Terms" ||
              this.$router.currentRoute.name == "Privacy" ||
              this.$router.currentRoute.name == "Login" ||
              this.$router.currentRoute.name == "SignUp")
    },
    showWork () {
      return !this.isSignedIn && (
              this.$router.currentRoute.name == "Home" ||
              this.$router.currentRoute.name == "ShareHome" ||
              this.$router.currentRoute.name == "Terms" ||
              this.$router.currentRoute.name == "Privacy" ||
              this.$router.currentRoute.name == "About" ||
              this.$router.currentRoute.name == "Login" ||
              this.$router.currentRoute.name == "SignUp")
    },
    showSignIn () {
      return this.$router.currentRoute.name == "Home" ||
            this.$router.currentRoute.name == "ShareHome" ||
            this.$router.currentRoute.name == "Thanks" ||
            this.$router.currentRoute.name == "CreateOrUpdateProject" ||
            this.$router.currentRoute.name == "UpdateProject" ||
            this.$router.currentRoute.name == "AddAsk" ||
            this.$router.currentRoute.name == "AddIntroVideoContainer" ||
            this.$router.currentRoute.name == "AddVideoContainer" ||
            this.$router.currentRoute.name == "AddVideoContainerWithId" ||
            this.$router.currentRoute.name == "InvitePeopleWithSlug" ||
            this.$router.currentRoute.name == "InvitePeople" ||
            this.$router.currentRoute.name == "AddBirthdayContacts" ||
            this.$router.currentRoute.name == "UpdateContactBirthdate" ||
            this.$router.currentRoute.name == "SignUp" ||
            this.$router.currentRoute.name == "UpdateContactBirthdate" ||
            this.$router.currentRoute.name == "UpdateContactBirthdateWitSlug" ||
            this.$router.currentRoute.name == "CreateBirthdayVideoContainer" ||
            this.$router.currentRoute.name == "CreateBirthdayProject"
    },
    useDark () {
      return ((this.$router.currentRoute.name == "Home" || this.$router.currentRoute.name == "ShareHome") && this.isSignedIn) ||
              this.$router.currentRoute.name == "CreateOrUpdateProject"  ||
              this.$router.currentRoute.name == "UpdateProject" ||
              this.$router.currentRoute.name == "AddAsk" ||
              this.$router.currentRoute.name == "Done" ||
              this.$router.currentRoute.name == "SignUp" ||
              this.$router.currentRoute.name == "Login" ||
              this.$router.currentRoute.name == "LoginWithNext" ||
              this.$router.currentRoute.name == "InvitePeople" ||
              this.$router.currentRoute.name == "InvitePeopleWithSlug" ||
              this.$router.currentRoute.name == "About" ||
              this.$router.currentRoute.name == "Privacy" ||
              this.$router.currentRoute.name == "Terms" ||
              this.$router.currentRoute.name == "Credits" ||
              this.$router.currentRoute.name == "Thanks" ||
              this.$router.currentRoute.name == "AddBirthdayContacts" ||
              this.$router.currentRoute.name == "UpdateContactBirthdate" ||
              this.$router.currentRoute.name == "UpdateContactBirthdateWitSlug" ||
              this.$router.currentRoute.name == "CreateBirthdayProject" ||
              this.$router.currentRoute.name == "UnsubscribeEmail"
    },
    isSignedIn () {
      return this.$store.getters.signedIn
    },
    currentUser () {
      return this.$store.state.user.user
    },
  },
  data () {
    return {
      isSmallScreen: config.isSmallScreen(),
      hoverMenu: false,
    }
  },
  methods: {
    create: function() {
      this.$store.commit('clearProject')
      this.$router.push({name:"CreateOrUpdateProject"})
    },
    onResize() {
      // console.log('window has been resized', event)
      this.isSmallScreen = config.isSmallScreen()
      this.$emit('is-small-screen', this.isSmallScreen)
    },
    next: function (nextName) {
      this.$router.push({name:nextName})
    },
    signOut: function() {
      this.hoverMenu = false
      this.$store.commit('clearUser')
      this.$store.commit('clearToken')
      this.$store.commit('clearProject')
      this.$router.push({name:"Home"})
    }
  },
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.onResize)
  },
  mounted: function () {
    window.addEventListener('resize', this.onResize)

    this.$emit('is_small_screen', config.isSmallScreen())

    // window.vue = this
    // window.config = config
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#logo_container {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 22px;
}

#header {
  width: 100%;
  position: relative;
  z-index: 2000;
}

.logo, .logotype {
  display: inline-block;
}

.logo {
  width: 48px;
  height: 20px;
}
.logotype {
  margin-left: 10px;
  opacity: 0.7;
}

#header button  {
  display: inline-block;
  float: right;
  padding: 8px 26px;
  color: RGBA(255, 255, 255, .8);
  background-color: RGBA(28, 32, 54, .18);
  margin-left: 12px;
}

#header .smallScreen button {
  margin-bottom: 12px;
}

#header .dark button {
  background-color: #F6F7F6;
  color: #35393C;
}

.signed_in_email {
  font-family: gibsonregular;
  font-size: 16px;
  color: #A2A9AE;
  text-align: center;
}

.hover_menu {
  position: absolute;
  z-index: 5000;
  top: 90%;
  right: 0px;
  padding: 12px 20px;
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;

  font-family: gibsonsemibold;
  font-size: 16px;

  color: #151516;
  box-shadow: 4px 4px 12px 12px rgba(0,0,0,0.04);
  -webkit-box-shadow: 4px 4px 12px 12px rgba(0,0,0,0.04);
  -moz-box-shadow: 4px 4px 12px 12px rgba(0,0,0,0.04);
}
.hover_menu a {
  display: block;
  color: #151516;
  text-decoration: none;
  margin-bottom: 6px;
}
.hover_menu a:hover {
  text-decoration: underline;
}
</style>
